export default function ThankYouTestdrive() {
  return (
    <div className="container flex flex-col items-center justify-center min-h-screen mx-auto bg-[url('../public/images/mustang-testdrive/Mustang-ThankYou-Background.jpg')] bg-center">
      <h1 className="text-4xl text-center text-white">
        Благодарим за запитването за тестдрайв!
      </h1>
      <p className="mt-8 text-white">
        Наш представител ще се свърже с Вас възможно най-скоро
      </p>
    </div>
  );
}
