import { Link, useLoaderData } from "react-router-dom";

import { db } from "../firebase/config";
import { collection, getDocs } from "firebase/firestore";

function Hotlist() {
  const hotlistName = "Горещи летни оферти";
  document.title = "FORD - " + hotlistName;

  const hotlist = [
    "VEH170811",
    "VEH174371",
    "VEH170694",
    "VEH170695",
    "VEH170696",
    "VEH170697",
    "VEH170699",
    "VEH170701",
    "VEH164901",
    "VEH164909",
    "VEH166218",
    "VEH166221",
    "VEH168755",
    "VEH163466",
    "VEH168177",
    "VEH168184",
    "VEH168186",
    "VEH168198",
    "VEH168194",
    "VEH168160",
    "VEH168166",
    "VEH168065",
    "VEH168347",
    "VEH167621",
    "VEH168087",
    "VEH168163",
    "VEH168170",
    "VEH167604",
    "VEH174983",
    "VEH174985",
    "VEH170478",
    "VEH170479",
    "VEH170480",
    "VEH170481",
    "VEH170476",
    "VEH170474",
    "VEH170475",
    "VEH171461",
    "VEH171462",
    "VEH171455",
    "VEH171457",
    "VEH170482",
    "VEH170483",
    "VEH170747",
    "VEH170746",
    "VEH173773",
    "VEH173770",
    "VEH173771",
    "VEH173772",
    "VEH173813",
    "VEH173816",
    "VEH173195",
    "VEH172207",
    "VEH172205",
    "VEH170332",
    "VEH169776",
    "VEH169773",
    "VEH164464",
    "VEH166233",
    "VEH165135",
    "VEH169462",
    "VEH169463",
    "VEH169464",
    "VEH165128",
  ];

  const cars = useLoaderData();
  cars.sort((a, b) => a.new_price - b.new_price);

  return (
    <div>
      <div className="bg-[url('../public/images/summer2024/summer-campaign-2024.jpg')] bg-contain bg-no-repeat h-60 lg:bg-cover lg:h-96">
        {/* <h1 className="my-16 text-2xl font-bold tracking-wide text-center text-white uppercase">
          {hotlistName}
        </h1> */}
      </div>
      <div className="container grid grid-cols-1 gap-4 px-4 md:grid-cols-2 lg:grid-cols-4">
        {/* <div className="block">{cars && <Filter cars={cars} />}</div> */}
        {cars.map((car) => {
          if (hotlist.includes(car.id)) {
            let imageURL =
              "../images/" +
              car.model
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .replaceAll(/-/g, "")
                .replaceAll(/\//g, "") +
              "-" +
              car.body
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .replaceAll(/-/g, "")
                .replaceAll(/\//g, "") +
              "-" +
              car.trim
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .replaceAll(/-/g, "")
                .replaceAll(/\//g, "") +
              "-" +
              car.color
                .toLowerCase()
                .replaceAll(/\s/g, "")
                .replaceAll(/-/g, "")
                .replaceAll(/\//g, "") +
              ".jpeg";

            // if (specialIds.includes(car.id)) {
            //   imageURL = imageURL.replace(".jpeg", "-copy.jpeg");
            // }

            let discount =
              ((car.old_price - car.new_price) / car.old_price) * 100;
            return (
              <div
                layout
                className="p-2 mt-4 shadow-md item hover:shadow-lg"
                key={car.id}
                id="the-list"
              >
                <div className="flex items-start justify-between">
                  <div className="min-h-[92px]">
                    <h3 className="mt-2 text-2xl uppercase text-fordBlue">
                      {car.model}
                    </h3>
                    <p className="text-sm text-gray-500">Оферта {car.id}</p>
                  </div>
                  {discount !== 0 && (
                    <div className="flex flex-col px-4 py-2  text-center rounded-sm bg-fordLight min-w-[180px]">
                      <p className="text-sm tracking-widest text-white uppercase">
                        Специална оферта
                      </p>
                      <p className="text-sm tracking-widest text-center text-white uppercase ">
                        -
                        {new Intl.NumberFormat("bg", {
                          maximumFractionDigits: 0,
                        }).format(discount)}
                        %
                      </p>
                    </div>
                  )}
                </div>
                <img src={imageURL} alt="" />
                <div className="flex justify-between">
                  <p className="text-gray-500">Гориво</p>
                  <p className="text-fordBlue">{car.fuel}</p>
                </div>
                <div className="flex justify-between">
                  <p className="text-gray-500">Скоростна кутия</p>
                  <p className="text-fordBlue">{car.gearbox}</p>
                </div>
                <p className="p-4 mt-4 text-xl text-center bg-gray-50">
                  Цена:{" "}
                  {new Intl.NumberFormat("bg", {
                    minimumFractionDigits: 0,
                  }).format(car.new_price)}{" "}
                  <span className="text-sm">лв. с ДДС</span>
                </p>
                <div className="mx-auto text-center">
                  <Link to={`/${car.id}`}>
                    <button className="mt-4 mb-2 border btn-primary border-fordBlue hover:bg-white hover:text-fordBlue">
                      Виж още
                    </button>
                  </Link>
                </div>
              </div>
            );
          } else {
            return;
          }
        })}
      </div>
    </div>
  );
}

export default Hotlist;

export const carsLoader = async () => {
  let results = [];
  const querySnapshot = await getDocs(collection(db, "cars"));
  querySnapshot.forEach((doc) => {
    results.push({ id: doc.id, ...doc.data() });
  });

  // Сортиране във възходящ ред по нова цена
  results.sort((a, b) => b.new_price - a.new_price);

  return results;
};
