import { motion } from "framer-motion";

export default function Modal({ children, handleClose }) {
  return (
    <div>
      <div
        className="fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-70"
        onClick={handleClose}
      ></div>
      <motion.div
        className="absolute z-50 flex flex-col w-4/5 -translate-x-1/2 -translate-y-1/2 sm:w-1/2 top-1/2 left-1/2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div
          className="text-xl text-white cursor-pointer text-end"
          onClick={handleClose}
        >
          &#x2715;
        </div>
        <div className="p-2 bg-white ">{children}</div>
      </motion.div>
    </div>
  );
}
