import { Outlet, useNavigate } from "react-router-dom";

export const Stats = () => {
  const navigate = useNavigate();
  // const [table, setTable] = useState(null);
  const changeTable = (e) => {
    navigate(e.target.value);
  };
  return (
    <div>
      <select onChange={changeTable}>
        <option value="" disabled selected>
          Изберете списък със запитвания
        </option>
        <option value="/admin/stats/offer-requests">
          Запитвания за специална оферта за конкретен автомобил
        </option>
        <option value="testdrive-requests">
          Запитвания за тестдрайв на конкретен автомобил
        </option>
        <option value="transit-connect-requests">
          Запитвания за оферта за промоция на Transit Connect
        </option>
        <option value="puma-kuga-requests">
          Запитвания за оферта за промоция за Puma и Kuga
        </option>
        <option value="puma-offer-requests">
          Запитвания за оферта за Puma с 3 900 лв. първоначални разходи
        </option>
        <option value="puma-getintouch-requests">
          Свържи се с търговец за Puma
        </option>
        <option value="ranger-requests">Запитвания за Ranger Raptor</option>
        <option value="testdrive-page-requests">
          Общи запитвания за тестдрайв
        </option>
        <option value="mach-e-offer-requests">
          Запитвания за оферта за Mach-E
        </option>
        <option value="mach-e-getintouch-requests">
          Свържи се с търговец за Mach-E
        </option>
        <option value="transit-courier-getintouch-requests">
          Свържи се с търговец за Transit Courier
        </option>
        <option value="transit-connect-requests">
          Свържи се с търговец за Transit Connect
        </option>
        <option value="transit-custom-requests">
          Свържи се с търговец за Transit Custom
        </option>
        <option value="kuga-proposal-requests">
          Запитвания за оферта за Kuga
        </option>
        <option value="kuga-getintouch-requests">
          Свържи се с търговец за Kuga
        </option>
        <option value="bronco-testdrive-requests">
          Запитвания за тестдрайв за Bronco
        </option>
        <option value="summer2024-requests">
          Запитвания за оферта - лято'24
        </option>
        <option value="mustang-testdrive-requests">
          Запитвания за тестдрайв на Mustang
        </option>
      </select>
      <Outlet />
    </div>
  );
};
